<template>
  <v-dialog v-model="modalData.dialog" persistent max-width="1650" scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="permissionCan('create')"
        color="primary"
        dark
        class="mb-2 ml-4"
        v-bind="attrs"
        v-on="on"
        @click="handleNew"
      >
        New Price list
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formTitle }}</span>
            <v-btn
              @click="handleCloseModalForm"
              style="float: right; cursor: pointer"
              icon
              color="#26223c"
            >
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text style="max-height: 90%">
        <v-form v-model="formValid" ref="form">
          <v-row>
            <!-- <v-col cols="12" sm="2" md="2">
              <v-btn
                depressed
                color="primary"
                class="ma-2"
                v-if="!clonePriceList && formModel.id"
                @click="handleClonePriceList"
              >
                Clone price list
              </v-btn>
            </v-col> -->
            <v-spacer></v-spacer>
            <v-col cols="12" sm="2" md="2">
              <v-select
                v-if="statuses.inventory"
                v-model="formModel.status"
                :items="statuses.inventory.priceList"
                :id="dynamicID"
                :label="$t('FORMS.status')"
                item-value="key"
              >
                <template v-slot:selection="data">
                  {{ $t("STATUSES." + data.item.value) }}
                </template>
                <template v-slot:item="data">
                  {{ $t("STATUSES." + data.item.value) }}
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-container>
            <v-row>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  v-model="formModel.name"
                  :label="$t('PRICE_LIST.priceListName')"
                  :rules="nameRules"
                  :id="dynamicID"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-select
                  :disabled="currencyDisabled"
                  v-model="formModel.currency_id"
                  :items="currencyCollection"
                  item-text="sign"
                  item-value="id"
                  :label="$t('PRICE_LIST.currency')"
                  :rules="currencyRules"
                  :id="dynamicID"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  :disabled="clonePriceList"
                  v-model="formModel.vat_name"
                  :label="$t('PRICE_LIST.defaultTaxName')"
                  :id="dynamicID"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  :disabled="clonePriceList"
                  v-model="formModel.vat_value"
                  :label="$t('PRICE_LIST.defaultTaxRate')"
                  :id="dynamicID"
                >
                  <v-icon slot="append" color="grey"> mdi-percent </v-icon>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-card>
                  <v-card-title>
                    <v-form v-model="formItemDataValid" ref="formItemData">
                      <v-row> {{ $t("PRICE_LIST.productsOnPriceList") }}</v-row>
                      <v-row>
                        <v-col cols="12" sm="2" md="2">
                          <!-- :change="handleChangeItem(itemInputData.item)" -->
                          <v-autocomplete
                            v-model="itemInputData.item"
                            :items="filteredProductCollection"
                            item-text="name"
                            :label="$t('PRICE_LIST.searchProduct')"
                            return-object
                            :disabled="isEditedItemData"
                            :rules="requiredRules"
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="1" md="1">
                          <v-text-field
                            v-model="itemInputData.currency_id"
                            :label="$t('PRICE_LIST.currency')"
                            disabled
                            :id="dynamicID"
                          ></v-text-field>
                        </v-col>
                        <!-- <v-col cols="12" sm="1" md="1">
                          <div class="d-flex flex-row">
                            <div>

                              <v-select
                                v-if="itemInputData.item"
                                v-model="itemInputData.quantity"
                                :items="computedQuantities"
                                label="Quantity"
                                :rules="requiredRules"
                                :id="dynamicID"
                                :disabled="isEditedItemData"
                              />
                            </div>

                            <div class="pa-1 mt-4" style="white-space: nowrap">
                              {{ itemInputData.quantityUnitTranslated }}
                            </div>
                          </div>
                        </v-col> -->

                        <v-col cols="12" sm="1" md="1">
                          <v-text-field
                            v-model="itemInputData.price_net"
                            :label="$t('PRICE_LIST.productPriceNet')"
                            type="number"
                            :rules="requiredRules"
                            v-on:input="handleCalculateBrutto"
                            :id="dynamicID"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="1" md="1">
                          <v-text-field
                            v-model="itemInputData.vat_name"
                            :label="$t('PRICE_LIST.defaultTaxName')"
                            :id="dynamicID"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="1" md="1">
                          <v-text-field
                            v-model="itemInputData.vat_value"
                            :label="$t('PRICE_LIST.defaultTaxRate')"
                            v-on:input="handleCalculateBrutto"
                            type="number"
                            min="0"
                            :id="dynamicID"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="1" md="1">
                          <v-text-field
                            v-model="itemInputData.price_gross"
                            :label="$t('PRICE_LIST.productPriceGross')"
                            type="number"
                            :rules="requiredRules"
                            v-on:input="handleCalculateNetto"
                            :id="dynamicID"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2" md="2">
                          <v-text-field
                            :id="dynamicID"
                            v-model="itemInputData.landing_url"
                            label="Landing URL"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2" md="2">
                          <v-btn
                            color="success"
                            @click="handleAddItemData"
                            class="ma-2"
                          >
                            {{ $t("FORMS.add") }}
                          </v-btn>
                          <v-btn
                            class="ma-2"
                            @click="handleCancelItemData"
                            :disabled="!cancelItemData"
                          >
                            {{ $t("FORMS.cancel") }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-form>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      :label="$t('FORMS.search')"
                      single-line
                      hide-details
                      :id="dynamicID"
                    ></v-text-field>
                  </v-card-title>

                  <v-data-table
                    :headers="headers"
                    :items="formModel.products"
                    :search="search"
                  >
                    <template v-slot:item.price_net="{ item }">
                      {{
                        $helpers.getNicePrice(
                          item.price_net,
                          itemInputData.currency_id
                        )
                      }}
                    </template>
                    <template v-slot:item.price_gross="{ item }">
                      {{
                        $helpers.getNicePrice(
                          item.price_gross,
                          itemInputData.currency_id
                        )
                      }}
                    </template>
                    <template v-slot:item.url="{ item }">
                      <a :href="item.landing_url" target="_blank">{{
                        item.landing_url
                      }}</a>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-icon
                        small
                        class="mr-2"
                        @click="handleEditItem(Object.assign({}, item))"
                        :disabled="isEditedItemData"
                      >
                        mdi-pencil
                      </v-icon>
                      <v-icon small @click="handleDeleteItem(item)">
                        mdi-delete
                      </v-icon>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="handleCloseModalForm">
          Cancel
        </v-btn>

        <v-btn
          color="blue darken-1"
          text
          @click="handleSaveModalForm"
          v-if="
            (permissionCan('create') && !this.editedItem) ||
            permissionCan('update')
          "
        >
          Save
        </v-btn>
      </v-card-actions>
      <v-dialog v-model="dialogDeleteItem" max-width="500px">
        <v-card>
          <v-card-title class="headline"
            >Are you sure you want to delete this item?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDeleteItem"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="deleteItemConfirm"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- <pre>
        {{ formModel.items }}
      </pre> -->
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
// import { mapGetters, mapActions } from "vuex";

import i18nService from "@/core/services/i18n.service.js";

const TRANSLATED_ATRIBUTES = [
  // "name"
];

export const initialFormData = () => ({
  id: null,
  status: 1,
  name: "",
  vat_value: "27",
  vat_name: "",
  currency_id: null,
  // items: [],
  products: [],
  translations: {},
});

export const initialItemInputData = {
  product_id: null,
  item: {},
  name: "",
  reference: "",
  // quantityUnitTranslated: "",
  currency_id: null,
  price_net: null,
  vat_name: null,
  vat_value: null,
  price_gross: null,
  landing_url: null,
  quantity: null,
};

export default {
  name: "PriceListModalForm",
  props: [
    "modalData",
    "statuses",
    "currencyCollection",
    "productCollection",
    "permissions",
    "loader",
  ],
  data() {
    return {
      items: ["1", "2", "3"],
      languages: i18nService.languages,
      selectedLocale: i18nService.languages[0],
      formModel: Object.assign({}, initialFormData()),
      formValid: false,
      clonePriceList: false,
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => v.length > 2 || "Name must be min 3 characters",
      ],
      vat_nameRules: [
        (v) => v.length > 2 || "Default tax name must be min 3 characters",
      ],
      vat_valueRules: [
        (v) => !!v || "Default tax rate is required",
        (v) => (v > 0 && v < 100) || "The value must be between 0 and 99.99",
        // (v) => v.length < 6 || "The value must be max 5 characters",
      ],
      requiredRules: [(v) => !!v || "Field is required"],

      currencyRules: [(v) => !!v || "Default tax rate is required"],

      search: "",
      headers: [
        // {
        //   text: "Reference",
        //   value: "reference",
        // },
        // {
        //   text: "Quantity",
        //   value: "quantity",
        // },
        // {
        //   text: "Quantity unit",
        //   // filterable: false,
        //   value: "quantityUnitTranslated",
        // },
        {
          text: this.$t("PRICE_LIST.productName"),
          // filterable: false,
          value: "name",
        },
        {
          text: this.$t("PRICE_LIST.productPriceNet"),
          value: "price_net",
          filterable: false,
        },
        {
          text: this.$t("PRICE_LIST.defaultTaxName"),
          value: "vat_name",
          filterable: false,
        },
        {
          text: this.$t("PRICE_LIST.defaultTaxRate"),
          value: "vat_value",
          filterable: false,
        },
        {
          text: this.$t("PRICE_LIST.productPriceGross"),
          value: "price_gross",
          filterable: false,
        },
        {
          text: "Landing URL",
          value: "url",
          filterable: false,
        },
        {
          text: "Actions",
          value: "actions",
          filterable: false,
        },
      ],

      formItemDataValid: false,
      formItemData: false,
      itemInputData: Object.assign({}, initialItemInputData),
      cancelItemData: null,
      isEditedItemData: false,
      itemTodelete: {},
      dialogDeleteItem: false,
    };
  },
  computed: {
    // ...mapGetters(["quantityUnitCollection"]),
    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },
    currencyDisabled() {
      if (this.formModel) {
        return this.formModel.id ? 1 : 0;
      } else {
        return 0;
      }
    },
    formTitle() {
      if (this.clonePriceList) return "Clone price list";
      return !this.formModel.id ? "New price list" : "Edit price list";
    },

    filteredProductCollection() {
      // return this.itemCollection;

      let productIds = this.formModel.products.map((item) => item.product_id);

      return this.productCollection.filter((item) => {
        return !productIds.includes(item.id);
      });

      // var counts = {};
      // itemIds.forEach(function (x) {
      //   counts[x] = (counts[x] || 0) + 1;
      // });
      // return this.productCollection.filter((item) => {
      //   return item.default_quantities.length != counts[item.id];
      // });
    },

    // computedQuantities() {
    //   if (!this.itemInputData.item || !this.itemInputData.item.id) return [];
    //   let quantities = this.itemInputData.item.default_quantities || [];
    //   let usedQuantities = this.formModel.items
    //     .filter((i) => {
    //       return i.item_id == this.itemInputData.item.id;
    //     })
    //     .map((i) => {
    //       return 1 * i.quantity;
    //     });

    //   return quantities.filter((q) => {
    //     return !usedQuantities.includes(1 * q);
    //   });
    // },
  },
  watch: {
    modalData: {
      deep: true,
      handler(value) {
        this.formModel = value.editedItem;
      },
    },

    formModel: {
      deep: true,
      handler(value) {
        this.itemInputData.currency_id = value.currency_id;
        if (!this.isEditedItemData) {
          this.itemInputData.vat_name = value.vat_name;
          this.itemInputData.vat_value = value.vat_value;
        }
      },
    },
  },
  methods: {
    // ...mapActions(["fetchQuantityUnit"]),
    handleCloseModalForm() {
      this.clonePriceList = false;
      this.itemInputData = Object.assign({}, initialItemInputData);
      this.cancelItemData = null;
      this.isEditedItemData = false;
      this.$emit("closeModalForm");
    },
    handleSaveModalForm() {
      this.$refs.form.validate();
      if (this.formValid) {
        this.clonePriceList = false;
        this.$emit("saveModalForm", Object.assign({}, this.formModel));
      }
    },
    setTranslatedAttributes(newItem = false) {
      if (newItem) {
        this.languages.forEach((language) => {
          TRANSLATED_ATRIBUTES.forEach((attribute) => {
            this.formModel.translations[language.lang] = {};
            this.formModel.translations[language.lang][attribute] = "";
          });
        });
      } else {
        this.languages.forEach((language) => {
          TRANSLATED_ATRIBUTES.forEach((attribute) => {
            if (!this.formModel.translations[language.lang]) {
              this.formModel.translations[language.lang] = {};
              this.formModel.translations[language.lang][attribute] = "";
            }
          });
        });
      }
    },

    handleCalculateBrutto() {
      if (this.itemInputData.price_net) {
        this.itemInputData.price_gross = this.getFormattedPrice(
          Number(this.itemInputData.price_net) *
            (Number(this.itemInputData.vat_value) / 100 + 1)
        );
      }
    },

    handleCalculateNetto() {
      if (this.itemInputData.price_gross) {
        this.itemInputData.price_net = this.getFormattedPrice(
          Number(this.itemInputData.price_gross) /
            (Number(this.itemInputData.vat_value) / 100 + 1)
        );
      }
    },

    getFormattedPrice(price, currency = this.itemInputData.currency_id) {
      if (price == 0 || !price || isNaN(price)) return "";

      if (currency == "HUF") {
        return Math.round(price);
      }

      return (1 * price).toFixed(2);
    },

    handleNew() {
      this.clonePriceList = false;
      if (this.$refs.formItemData) {
        this.$refs.formItemData.resetValidation();
      }
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    },

    handleAddItemData() {
      this.$refs.formItemData.validate();
      if (this.formItemDataValid) {
        this.itemInputData.product_id = this.itemInputData.item.id;
        this.itemInputData.name = this.itemInputData.item.name;
        this.itemInputData.reference = this.itemInputData.item.reference;
        if (!this.itemInputData.landing_url)
          this.itemInputData.landing_url = "";
        this.formModel.products.unshift(Object.assign({}, this.itemInputData));
        this.itemInputData = Object.assign({}, this.initialItemInputData);
        this.$refs.formItemData.resetValidation();
        this.isEditedItemData = false;
        this.cancelItemData = null;
      }
    },

    handleCancelItemData() {
      this.formModel.products.unshift(Object.assign({}, this.cancelItemData));
      this.itemInputData = Object.assign({}, this.initialItemInputData);
      this.$refs.formItemData.resetValidation();
      this.cancelItemData = null;
      this.isEditedItemData = false;
    },

    handleEditItem(item) {
      if (!this.isEditedItemData) {
        this.itemInputData = { ...item };
        this.cancelItemData = { ...item };
        this.formModel.products = this.formModel.products.filter((i) => {
          // return item.price_list_item_id != i.price_list_item_id;
          return item.price_list_product_id != i.price_list_product_id;
        });

        this.isEditedItemData = true;
      }
    },

    handleDeleteItem(item) {
      this.itemTodelete = this.formModel.products.find(
        // (i) => i.price_list_item_id == item.price_list_item_id
        (i) => i.price_list_product_id == item.price_list_product_id
      );

      this.dialogDeleteItem = true;
    },

    deleteItemConfirm() {
      this.formModel.products.splice(
        this.formModel.products.indexOf(this.itemTodelete),
        1
      );

      this.closeDeleteItem();
    },

    closeDeleteItem() {
      this.itemTodelete = {};
      this.dialogDeleteItem = false;
    },

    handleClonePriceList() {
      this.formModel.id = null;
      this.formModel.name = "";
      this.clonePriceList = true;
    },

    permissionCan(action) {
      return this.permissions.find(
        (x) => x.name == "products.price_list." + action
      );
    },
  },
};
</script>
